import { AppRouteNames } from '../../../app.routenames';
import { SVLRouteValue } from '@domain/shared/svl-route-value';

export interface ErrorRouteNamesType {
    error: SVLRouteValue;
    error_404: SVLRouteValue;
    error_401: SVLRouteValue;
    error_403: SVLRouteValue;
    error_500: SVLRouteValue;
}

export const ErrorRouteNames: ErrorRouteNamesType = {
    error: {
        local: 'error',
        global: AppRouteNames.error,
    },
    error_404: {
        local: '404',
        global: AppRouteNames.error + '/404',
    },
    error_401: {
        local: '401',
        global: AppRouteNames.error + '/401',
    },
    error_403: {
        local: '403',
        global: AppRouteNames.error + '/403',
    },
    error_500: {
      local: '500',
      global: AppRouteNames.error + '/500',
  },
};
